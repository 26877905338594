"use client";

import { ColumnDef } from "@tanstack/react-table";
import { ErrorSignature } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import React, { useState, useEffect } from "react";
import Label from "@/components/ui/label";
import { MagicWandIcon } from "@radix-ui/react-icons";
import { Checkbox } from "@/components/ui/checkbox";
import { LuInfo as InfoIcon } from "react-icons/lu";
import { PiArrowSquareOutFill } from "react-icons/pi";
import {
  Tooltip,
  TooltipProvider,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";

import OccurrenceGraph from "./occurrence-graph";
import ImpactLabel from "./impact-label";
import { ImpactCategory } from "@/app/issue/types";
import { TimeAgo } from "../time-ago";


export const columnTitles = {
  status_code: "Status Code",
  label: "Type",
  title: "Issue",
  codebase: "Codebase",
  total_instances: "Count",
  occurrence_graph: "Frequency",
  most_recent_instance_time: "Last Occurred",
  affected_endpoints: "Endpoints",
};

const GetColumnHeader = ({ column }) => {
  return (
    <DataTableColumnHeader column={column} title={columnTitles[column.id]} />
  );
};

const getHighestSeverityImpactSummary = (row) => {
  if (row.original.combined_impact_summary) {
    return row.original.combined_impact_summary;
  } else if (row.original.most_recent_crashed_impact_summary) {
    return row.original.most_recent_crashed_impact_summary;
  } else if (row.original.most_recent_did_not_load_impact_summary) {
    return row.original.most_recent_did_not_load_impact_summary;
  } else if (row.original.most_recent_unexpected_behavior_impact_summary) {
    return row.original.most_recent_unexpected_behavior_impact_summary;
  } else if (row.original.most_recent_unknown_impact_summary) {
    return row.original.most_recent_unknown_impact_summary;
  } else {
    return row.original.impact_summary_short;
  }
};

const OccurrenceGraphHeader = ({ column }) => {
  const { timeRange, setTimeRange } = column.columnDef.meta;
  const [selectedOption, setSelectedOption] = useState(timeRange);

  const handleClick = (range: string) => {
    setSelectedOption(range);
    setTimeRange(range); // This will trigger a refetch in the cell
  };
  const selectedClass = "p-1 rounded bg-gray-600 text-white";
  return (
    <div className="flex space-x-2">
      <button
        className={`${selectedOption === "7d" ? selectedClass : "p-1 rounded"}`}
        onClick={() => handleClick("7d")}
      >
        7d
      </button>
      <button
        className={`${
          selectedOption === "24h" ? selectedClass : "p-1 rounded"
        }`}
        onClick={() => handleClick("24h")}
      >
        24h
      </button>
      <button
        className={`${selectedOption === "1h" ? selectedClass : "p-1 rounded"}`}
        onClick={() => handleClick("1h")}
      >
        1h
      </button>
    </div>
  );
};

interface ImpactCounts {
  Crashed: number;
  "Did Not Load": number;
  "Unexpected Behavior": number;
  Unknown: number;
  "Works As Intended": number;
}

function useImpactCounts(signatureId: number) {
  const [impactCounts, setImpactCounts] = useState<ImpactCounts | null>(null);

  useEffect(() => {
    const fetchImpactCounts = async () => {
      const response = await fetch("/api/get_categorized_error_counts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ signatureId }),
      });
      const data = await response.json();
      setImpactCounts(data);
    };

    fetchImpactCounts();
  }, [signatureId]);

  return impactCounts;
}

export const columns: ColumnDef<ErrorSignature>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        onClick={(e) => e.stopPropagation()} // Add this line
      />
    ),
  },
  {
    accessorKey: "title",
    header: GetColumnHeader,
    cell: ({ row }) => {
      // const impactCounts = useImpactCounts(row.original.signature_id);

      // HACK to show an error on first load for users.
      const hardcodedErrorCondition =
        row.original.title === "Server Error: Bad Request" &&
        row.original.label === "uncaughtException" &&
        row.original.codebase === "contractify_frontend" &&
        // This is the signature ID for this error on the Demo org, where we actually want to keep it shown as
        // a normal error.
        row.original.signature_id != 2851;

      return (
        <div className="flex flex-col  max-w-[800px] space-y-2 cursor-pointer px-4 py-2">
          <TooltipProvider delayDuration={20}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="min-w-[200px]  max-w-[800px] break-words font-medium line-clamp-3">
                  {row.getValue("title")}
                </div>
              </TooltipTrigger>
              <TooltipContent className="max-w-screen-sm">
                {row.getValue("title")}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <div className="flex flex-col space-y-2">
            <div className="flex space-x-2">
              {hardcodedErrorCondition && (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("https://docs.getdecipher.com", "_blank");
                  }}
                >
                  <Label
                    color="blueToGreenGradient"
                    thickness="thin"
                    filledIn={true}
                  >
                    <span className="flex items-center text-white">
                      <InfoIcon className="mr-1" size={16} /> Example error -
                      we'll remove this when we start capturing your errors!{" "}
                      <PiArrowSquareOutFill size={16} className="ml-1" />
                    </span>
                  </Label>
                </span>
              )}
              {row.original.status_code && (
                <Label color="blue" thickness="thin">
                  {row.original.status_code}
                </Label>
              )}
              {row.original.is_severe &&
                row.original.combined_impact_category && (
                  <ImpactLabel
                    key={row.original.signature_id}
                    category={
                      row.original.combined_impact_category
                        .replace(/\s+/g, "-")
                        .toLowerCase() as ImpactCategory
                    }
                    count={undefined}
                  />
                )}
            </div>
            <div className="flex space-x-2">
              {row.original.is_severe &&
                // || row.original.tmp_is_manual_high_sev)
                getHighestSeverityImpactSummary(row) && (
                  <Label color="tealToRedGradient">
                    <span className="flex items-center mb-2 font-medium">
                      <MagicWandIcon className="mr-2" /> Impact
                    </span>
                    {getHighestSeverityImpactSummary(row)}
                  </Label>
                )}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "codebase",
    header: GetColumnHeader,
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue("codebase")}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "total_instances",
    header: GetColumnHeader,
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="truncate font-medium">
            {row.getValue("total_instances")}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "occurrence_graph",
    header: OccurrenceGraphHeader,
    cell: ({ row, column }) => {
      const signatureId = row.original.signature_id;
      // @ts-expect-error
      const { timeRange }: { timeRange: "24h" | "7d" | "1h" | "" } =
        column.columnDef.meta;
      // @ts-expect-error
      const { isDemo } = column.columnDef.meta;
      return (
        <OccurrenceGraph
          timeRange={timeRange}
          signatureId={signatureId}
          isDemo={isDemo}
        />
      );
    },
  },
  {
    accessorKey: "most_recent_instance_time",
    header: GetColumnHeader,
    cell: ({ row }) => {
      const mostRecentInstanceTime = new Date(
        row.getValue("most_recent_instance_time")
      );
      return (
        <div className="flex flex-col space-y-2">
          <span className="max-w-[500px] truncate font-medium">
            {mostRecentInstanceTime.toLocaleString()}
          </span>
          <TimeAgo timestamp={mostRecentInstanceTime} />
        </div>
      );
    },
  },
  {
    accessorKey: "affected_endpoints",
    header: GetColumnHeader,
    cell: ({ row }) => {
      const affectedEndpoints = (row.getValue("affected_endpoints") as string[] | null)?.filter(endpoint => endpoint !== "N/A") || [];
      const maxDisplayEndpoints = 2;
      const displayEndpoints = affectedEndpoints.slice(0, maxDisplayEndpoints);
      const remainingEndpoints = affectedEndpoints.slice(maxDisplayEndpoints);
      const remainingCount = remainingEndpoints.length;
  
      return (
        <div className="flex flex-wrap gap-1">
          {displayEndpoints.map((endpoint, index) => (
            <span key={index} className="px-2 py-1 bg-gray-200 rounded-full text-xs truncate max-w-[150px]">
              {endpoint}
            </span>
          ))}
          {remainingCount > 0 && (
            <HoverCard>
              <HoverCardTrigger>
                <span className="px-2 py-1 bg-gray-200 rounded-full text-xs cursor-pointer">
                  +{remainingCount} more
                </span>
              </HoverCardTrigger>
              <HoverCardContent className="w-64">
                <div className="flex flex-col gap-1">
                  {remainingEndpoints.map((endpoint, index) => (
                    <span key={index} className="text-xs truncate">
                      {endpoint}
                    </span>
                  ))}
                </div>
              </HoverCardContent>
            </HoverCard>
          )}
          {affectedEndpoints.length === 0 && (
            <span className="text-gray-500">No affected endpoints</span>
          )}
        </div>
      );
    },
  },
];
