"use client";

import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

interface OccurrenceGraphProps {
  timeRange: "24h" | "7d" | "1h" | "";
  signatureId: number;
  isDemo: boolean;
}

function getMockFrequencyData(timeRange: string) {
  if (timeRange === "24h") {
    return Array.from({ length: 24 }, (_, i) => ({
      name: `${i}:00`,
      total: Math.floor(Math.random() * 100),
    }));
  }
  return Array.from({ length: 7 }, (_, i) => ({
    name: new Date(Date.now() - i * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0],
    total: Math.floor(Math.random() * 100),
  }));
}

const chartConfig = {
  count: {
    label: "Instances",
  },
  "1h": {
    label: "Last Hour",
    color: "hsl(var(--chart-5))",
  },
  "24h": {
    label: "Last 24h",
    color: "hsl(var(--chart-5))",
  },
  "7d": {
    label: "Last 7d",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig;

async function fetchFrequencyData({
  queryKey,
}: {
  queryKey: [string, { timeRange: string; signatureId: number }];
}) {
  const [, { timeRange, signatureId }] = queryKey;
  const response = await fetch("/api/get_frequency", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ timeRange, signatureId }),
  });
  if (!response.ok) {
    throw new Error("Frequency data fetch failure");
  }
  return response.json();
}

export default function OccurrenceGraph(props: OccurrenceGraphProps) {
  const { timeRange, signatureId, isDemo } = props;
  const query = useQuery({
    queryKey: ["frequencyData", { timeRange, signatureId }],
    queryFn: fetchFrequencyData,
    enabled: !isDemo,
    initialData: isDemo ? getMockFrequencyData(timeRange) : undefined,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
  const { data, isFetching, error } = query;

  if (error) {
    return <div>Error loading data</div>;
  }

  if (isFetching) {
    return (
      <div className="aspect-auto  animate-pulse max-w-[300px] min-w-[150px] w-full">
        <div className="h-8 max-w-[300px] min-w-[150px] bg-gray-500 rounded-sm animate-pulse"></div>
      </div>
    );
  }

  if (!data || data.length === 0 || data.every((item) => item.total === 0)) {
    return <div>No data in last {timeRange}</div>;
  }

  return (
    <ChartContainer
      config={chartConfig}
      className="aspect-auto h-[70px] max-w-[300px] min-w-[150px]"
    >
      {isFetching ? (
        <div className="flex justify-center items-center my-6">
          <div className="relative">
            <div className="w-8 h-8 bg-blue-500 rounded-full animate-ping"></div>
            <div className="absolute top-0 left-0 w-8 h-8 bg-blue-500 rounded-full opacity-75"></div>
          </div>
        </div>
      ) : (
        <BarChart accessibilityLayer data={data} margin={{ bottom: -30 }}>
          <CartesianGrid vertical={false} />
          <XAxis
          tickLine={false}
            dataKey="name"
            tickFormatter={(value) => {
              const date = new Date(value);
              if (timeRange === "1h" || timeRange === "24h") {
                const userTime = new Date(
                  date.getTime() - date.getTimezoneOffset() * 60000
                );
                return userTime.toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                });
              } else {
                const userTime = new Date(
                  date.getTime() - date.getTimezoneOffset() * 60000
                );
                return userTime.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                });
              }
            }}
          />
          <ChartTooltip
            content={
              <ChartTooltipContent
                className="w-[150px]"
                nameKey="count"
                labelFormatter={(value) => {
                  if (timeRange === "1h" || timeRange === "24h") {
                    const date = new Date(value);
                    const userTime = new Date(
                      date.getTime() - date.getTimezoneOffset() * 60000
                    );
                    const timeString = userTime.toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    });
                    const dateString = userTime.toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    });
                    return `${timeString} ${dateString}`;
                  } else {
                    const date = new Date(value);
                    const userTime = new Date(
                      date.getTime() - date.getTimezoneOffset() * 60000
                    );
                    return userTime.toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    });
                  }
                }}
              />
            }
          />
          <Bar dataKey={"total"} fill={`var(--color-${timeRange})`}  radius={[2, 2, 0, 0]} 
          
          />
        </BarChart>
      )}
    </ChartContainer>
  );
}
