"use client";
import { useSearchParams } from "next/navigation";
import * as React from "react";
import { useState, useEffect } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { DataTablePagination } from "@/components/ui/table/data-table-pagination";
import { DataTableToolbar } from "@/components/ui/table/data-table-toolbar";
import FloatingMenu from "@/components/ui/floating-menu";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import Link from "next/link";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorSignature } from "@/components/ui/data/schema";
import { getAppropriateIssueIdToLinkTo } from "@/app/_utils/error_signature_utils";
const queryClient = new QueryClient();

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  cid: string;
  isLoading?: boolean;
  initialCodebaseIds: string[];
  initialEndpoints: string[];
  defaultSeverityFilter: string;
  // We do certain things differently for the demo, like fake out the frequency data.
  isDemo?: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data: initialData,
  cid,
  isLoading = false,
  initialCodebaseIds,
  initialEndpoints,
  defaultSeverityFilter,
  isDemo,
}: DataTableProps<TData, TValue>) {
  const searchParams = useSearchParams();
  const [data, setData] = React.useState<TData[]>(initialData);
  const [codebaseIds, setCodebaseIds] =
    React.useState<string[]>(initialCodebaseIds);
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [timeRange, setTimeRange] = useState("24h");
  const validSeverityFromUrlOrNull = ["all", "high-severity"].includes(
    searchParams.get("severity") || ""
  )
    ? searchParams.get("severity")
    : null;
  const [severityFilter, setSeverityFilter] = useState(
    validSeverityFromUrlOrNull || defaultSeverityFilter || "high-severity"
  );
  const [endpoints, setEndpoints] = useState<string[]>(initialEndpoints);

  const table = useReactTable({
    data,
    columns: columns.map((column) => ({
      ...column,
      meta: { timeRange, setTimeRange, isDemo },
    })),
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const handleDataUpdate = (newData: TData[]) => {
    setData(newData);
  };

  useEffect(() => {
    const selectedRows = table.getSelectedRowModel().rows;
    setSelectedCount(selectedRows.length);
    setSelectedIds(
      selectedRows.map(
        (row) => (row.original as { signature_id: string }).signature_id
      )
    ); // Add this line
  }, [table.getSelectedRowModel().rows]);

  const handleDeselectAll = () => {
    table.resetRowSelection();
  };
  const handleRefresh = async () => {
    const statusFilter = searchParams.get("status") || "active";
    try {
      const response = await fetch("/api/error_search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: "",
          cid,
          status: statusFilter,
        }),
      });
      const respData = await response.json();
      setData(respData.filteredSignatures);
      setCodebaseIds(respData.codebaseIds);
      const extractedEndpoints = Array.from(
        new Set(
          respData.filteredSignatures
            .map((item: any) => item.endpoint)
            .filter(
              (endpoint) =>
                endpoint != null && endpoint !== undefined && endpoint !== "N/A"
            )
        )
      ) as string[];
      setEndpoints(extractedEndpoints);
    } catch (error) {
      console.error("Failed to refresh data", error);
    }
    setRowSelection({});
  };
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <div className="space-y-4">
        <DataTableToolbar
          table={table}
          cid={cid}
          onDataUpdate={handleDataUpdate}
          initialData={initialData}
          codebaseIds={codebaseIds}
          handleRefresh={handleRefresh}
          isDemo={!!isDemo}
          severityFilter={severityFilter}
          setSeverityFilter={setSeverityFilter}
          endpoints={endpoints}
        />
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {isLoading ? (
                      <TableCell colSpan={columns.length}>
                        <div className="h-8 flex justify-center items-center w-full">
                          <div className="h-2.5 bg-gray-500 rounded-full animate-pulse w-full"></div>
                        </div>
                      </TableCell>
                    ) : (
                      row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          onClick={(e) => {
                            if (cell.column.id === "select") {
                              e.stopPropagation();
                            }
                          }}
                        >
                          {cell.column.id === "select" ? (
                            flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          ) : (
                            <Link
                              key={row.id}
                              href={`/issue/${getAppropriateIssueIdToLinkTo(
                                row.original as ErrorSignature
                              )}${
                                (row.original as ErrorSignature).is_severe
                                  ? "/?impact=high-severity"
                                  : ""
                              }`}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Link>
                          )}
                        </TableCell>
                      ))
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <DataTablePagination table={table} />
        <FloatingMenu
          selectedCount={selectedCount}
          selectedIds={selectedIds}
          onDeselectAll={handleDeselectAll}
          onRefresh={handleRefresh}
        />
      </div>
    </QueryClientProvider>
  );
}
