"use client";

import { useRouter, useSearchParams } from "next/navigation";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { useEffect } from "react";
import { Toggle } from "@/components/ui/toggle";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";

import { columnTitles } from "./columns";

import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";

interface DataTableViewOptionsProps<TData> {
  table: Table<TData>;
  handleRefresh: () => void;
}

export function DataTableViewOptions<TData>({
  table,
  handleRefresh,
}: DataTableViewOptionsProps<TData>) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const statusFilter = searchParams.get("status") || "active";
  const hideLocalIssues = searchParams.get("hideLocalIssues") === "true";

  const updateQueryParams = (key: string, value: string) => {
    const params = new URLSearchParams(searchParams.toString());
    params.set(key, value);
    router.push(`/?${params.toString()}`);
  };

  const handleStatusChange = (value: string) => {
    updateQueryParams("status", value);
  };

  const handleHideLocalIssues = (value: string) => {
    updateQueryParams("hideLocalIssues", value);
  };

  //To-do clean this up later. Likely want to pass status to handleRefresh.
  useEffect(() => {
    handleRefresh();
  }, [searchParams.get("status"), searchParams.get("hideLocalIssues")]);

  return (
    <div className="flex items-center">
      <Select onValueChange={handleStatusChange} value={statusFilter}>
        <SelectTrigger className="w-[180px] mr-2 h-8">
          <SelectValue placeholder="Select Status" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="active">Active Issues</SelectItem>
          <SelectItem value="resolved">Resolved Issues</SelectItem>
        </SelectContent>
      </Select>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            className="ml-auto hidden h-8 lg:flex"
          >
            <MixerHorizontalIcon className="mr-2 h-4 w-4" />
            View
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[150px]">
          <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {table
            .getAllColumns()
            .filter(
              (column) =>
                typeof column.accessorFn !== "undefined" && column.getCanHide()
            )
            .map((column) => {
              return (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {columnTitles[(column.columnDef as any).accessorKey]}
                </DropdownMenuCheckboxItem>
              );
            })}
          <DropdownMenuSeparator />
          {/* <DropdownMenuLabel>Options</DropdownMenuLabel>
          <DropdownMenuCheckboxItem
            checked={hideLocalIssues}
            onCheckedChange={(value) => handleHideLocalIssues(value ? "true" : "false")}
          >
            Hide Local Errors
          </DropdownMenuCheckboxItem> */}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}