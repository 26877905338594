"use client";
import React, { useState, useEffect, useMemo } from "react"; // Import useState
import { MagicWandIcon } from "@radix-ui/react-icons";
import { CardStackIcon } from "@radix-ui/react-icons";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { DataTableViewOptions } from "@/components/ui/table/data-table-view-options";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/navigation";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  cid: string;
  onDataUpdate: (newData: TData[]) => void;
  initialData: TData[];
  codebaseIds: string[];
  handleRefresh: () => void;
  isDemo?: boolean;
  severityFilter: string;
  setSeverityFilter: React.Dispatch<React.SetStateAction<string>>;
  endpoints: string[];
}

const customerNamesForMock = [
  "Greenfield Services",
  "Innovative Billing Co.",
  "Horizon Logistics",
  "Vertex Solutions",
  "Quantum Dynamics",
  "Summit Technologies",
  "Pinnacle Law Associates",
];

export function DataTableToolbar<TData>({
  table,
  cid,
  onDataUpdate,
  initialData,
  codebaseIds,
  handleRefresh,
  isDemo,
  severityFilter,
  setSeverityFilter,
  endpoints,
}: DataTableToolbarProps<TData>) {
  const searchParams = useSearchParams();
  const validCodebaseFromUrlOrNull = codebaseIds.includes(
    searchParams.get("codebase") || ""
  )
    ? searchParams.get("codebase")
    : null;
  const validEndpointFromUrlOrNull = endpoints.includes(
    searchParams.get("endpoint") || ""
  )
    ? searchParams.get("endpoint")
    : null;
  const pathname = usePathname();
  const { replace } = useRouter();

  const [searchQuery, setSearchQuery] = useState(""); // State to hold the search query
  const [selectedCodebase, setSelectedCodebase] = useState<string>(
    validCodebaseFromUrlOrNull || "/all"
  );
  const [selectedCustomer, setSelectedCustomer] = useState<string>("/all");
  const [showOnlyKnownIssues, setShowOnlyKnownIssues] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState<string>(
    validEndpointFromUrlOrNull || "/all"
  );
  const [showToggle, setShowToggle] = useState(true);

  useEffect(() => {
    if (cid) {
      if (cid === "a40d7d7d-59aa-447e-a655-679a4107e548") {
        setShowToggle(false);
      }
    }
  }, [cid]);

  const isFiltered = table.getState().columnFilters.length > 0;

  // const updateTableParams = (key: string, value: string) => {
  //   const params = new URLSearchParams(searchParams.toString());
  //   params.set(key, value);
  //   console.log(params.toString());
  //   router.push(`/?${params.toString()}`);
  // };

  const filteredData = useMemo(() => {
    return initialData.filter((item: any) => {
      let searchOnlyKnownIssues = severityFilter === "high-severity";
      const matchesSearch =
        searchQuery === "" ||
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.endpoint.toLowerCase().includes(searchQuery.toLowerCase());

      if (searchQuery !== "") {
        searchOnlyKnownIssues = false;
      }

      let matchesCodebase =
        selectedCodebase === "/all" || item.codebase === selectedCodebase;
      let matchesEndpoint =
        selectedEndpoint === "/all" || item.endpoint === selectedEndpoint;
      if (isDemo && selectedCustomer != "/all") {
        matchesCodebase = Math.random() < 0.5;
        matchesEndpoint = Math.random() < 0.5;
      }

      const isKnownIssue = !searchOnlyKnownIssues || item.is_severe; // || item.tmp_is_manual_high_sev
      return (
        matchesSearch && matchesCodebase && isKnownIssue && matchesEndpoint
      );
    });
  }, [
    initialData,
    searchQuery,
    selectedCodebase,
    selectedEndpoint,
    selectedCustomer,
    showOnlyKnownIssues,
    severityFilter,
  ]);

  useEffect(() => {
    onDataUpdate(filteredData);
  }, [filteredData, onDataUpdate]);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleCodebaseChange = (newCodebaseFilter: string) => {
    setSelectedCodebase(newCodebaseFilter);
    const params = new URLSearchParams(searchParams);
    if (newCodebaseFilter) {
      params.set("codebase", newCodebaseFilter);
    } else {
      params.delete("codebase");
    }
    replace(`${pathname}?${params.toString()}`);
  };

  const handleCustomerChange = (value: string) => {
    setSelectedCustomer(value);
  };

  const handleKnownIssuesToggle = (checked: boolean) => {
    setShowOnlyKnownIssues(checked);
    // updateTableParams("showOnlyKnownIssues", checked.toString());
  };

  const handleSeverityFilterChange = (value: string) => {
    setSeverityFilter(value);
    const params = new URLSearchParams(searchParams);
    if (value) {
      params.set("severity", value);
    } else {
      params.delete("severity");
    }
    replace(`${pathname}?${params.toString()}`);
  };

  const handleEndpointFilterChange = (value: string) => {
    setSelectedEndpoint(value);
    const params = new URLSearchParams(searchParams);
    if (value && value !== "/all") {
      params.set("endpoint", value);
    } else {
      params.delete("endpoint");
    }
    replace(`${pathname}?${params.toString()}`);
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center space-x-2">
          <Input
            placeholder="Search across errors..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-[450px] h-[60px] lg:w-[450px] text-sm"
          />
          {isDemo && (
            <Select
              onValueChange={handleCustomerChange}
              value={selectedCustomer}
            >
              <SelectTrigger className="w-[180px] h-8">
                <SelectValue placeholder="Select Customer" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem key="all" value="/all">
                  All Customers
                </SelectItem>
                {customerNamesForMock.map((id) => (
                  <SelectItem key={id} value={id}>
                    {id}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => table.resetColumnFilters()}
              className="h-8 px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
        <DataTableViewOptions table={table} handleRefresh={handleRefresh} />
      </div>
      <div className="flex items-center space-x-2">
        {showToggle && (
          <Select
            onValueChange={handleSeverityFilterChange}
            value={severityFilter}
          >
            <SelectTrigger
              className={`w-[200px] ${
                severityFilter === "high-severity"
                  ? "bg-gradient-to-r bordder border-indigo-400 dark:border-indigo-600 from-blue-300 to-purple-300 dark:from-blue-800 dark:to-purple-900"
                  : ""
              }`}
            >
              <SelectValue placeholder="Select severity" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">
                <div className="flex items-center">
                  <CardStackIcon className="mr-2" />
                  All Errors
                </div>
              </SelectItem>
              <SelectItem value="high-severity">
                <div className="flex items-center">
                  <MagicWandIcon className="mr-2" />
                  High Severity Only
                </div>
              </SelectItem>
            </SelectContent>
          </Select>
        )}
        <Select onValueChange={handleCodebaseChange} value={selectedCodebase}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select Codebase" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem key="all" value="/all">
              All Codebases
            </SelectItem>
            {codebaseIds.map((id) => (
              <SelectItem key={id} value={id}>
                {id}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {endpoints && endpoints.length > 0 && (
          <Select
            onValueChange={handleEndpointFilterChange}
            value={selectedEndpoint}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select Endpoint" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem key="all" value="/all">
                All Endpoints
              </SelectItem>
              {endpoints.map((id) => (
                <SelectItem key={id} value={id}>
                  {id}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      </div>
    </div>
  );
}
